import { gql } from 'urql'

export const FragGoogleAdBannerSection = gql`
  fragment GoogleAdBannerSection on GoogleAdBannerSection {
    id
    googleAdBannerContent: content {
      ...GoogleAdBannerContent
    }
  }

  fragment GoogleAdBannerContent on GoogleAdBannerContent {
    __typename
    sizes {
      desktop {
        width
        height
      }
      mobile {
        width
        height
      }
    }
  }
`
